import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

/**
 * Bugsnag 🐛 (not bugherd!) service provides a component for error capture and methods to
 * identify the user in session and manually notify of errors not thrown.
 *
 * If you don't provide a bugsnag `client`, you must provide an `apiKey`
 * & (optionally) a `releaseStage` for us to create a client with.
 *
 * If `devMode: true`, the functions will do nothing, including the ErrorBoundary (just returns `children`).
 *
 * @param {{
 *  apiKey: String,
 *  releaseStage: String,
 *  devMode: boolean,
 *  client: object!
 * }} obj
 */
const createBugsnagService = ({
  apiKey,
  releaseStage,
  appVersion,
  devMode = false,
  client = Bugsnag.start({
    apiKey,
    releaseStage,
    beforeSend,
    appVersion,
    plugins: [new BugsnagPluginReact()],
  }),
} = {}) => {
  if (devMode) {
    console.warn('Bugsnag sends are disabled for local development');
    return {
      ErrorBoundary: ({ children }) => <>{children}</>, // eslint-disable-line
      identifyUser: noop,
      notifyError: noop,
      leaveBreadcrumb: noop,
    };
  }

  return {
    /** Captures all exceptions from child components. */
    ErrorBoundary: client.getPlugin('react').createErrorBoundary(React),
    /** Puts userId into context for any exceptions. */
    identifyUser: ({ id }) => client.setUser(id),
    /** Notifies service of errors without exceptions. */
    notifyError: (error, meta) => client.notify(error, meta),
    /** Log potentially useful events for bug tracking. */
    leaveBreadcrumb: (event, meta = {}) => client.leaveBreadcrumb(event, meta),
  };
};

/** @todo Redact any sensitive keys or urls. */
const redactedUrls = [];

export const beforeSend = (report) => {
  if (!report.request || !report.request.url) {
    console.log('Reporting error from unknown request and/or url', report);
  } else {
    console.log(`Reporting error to Bugsnag from ${report.request.url}`);
    if (redactedUrls.includes(report.request.url)) {
      report.request.url = '[REDACTED]';
    }
  }
};

const noop = () => {};

export default createBugsnagService;
