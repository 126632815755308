import createBugsnagService from './createBugsnagService';
import { getConfig } from '../config/get-config';

const { ACG_ENV, NEXT_PUBLIC_APP_VERSION, BUGSNAG_API_KEY, CYPRESS } = getConfig();

const isDev = ACG_ENV === 'dev' || CYPRESS;

export const bugsnag = createBugsnagService({
  apiKey: BUGSNAG_API_KEY,
  releaseStage: ACG_ENV,
  appVersion: NEXT_PUBLIC_APP_VERSION,
  devMode: isDev,
});

export default bugsnag;
