import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import omitDeep from 'omit-deep-lodash';
import get from 'lodash.get';
import { isUndefined } from 'lodash';
import { ErrorMessage } from '../components/ui/ErrorMessage';
import { getConfig } from '../config/get-config';
import trace from '../util/trace';

const { LEARN_SITE, CYPRESS } = getConfig();

const GET_QUESTIONS = gql`
  query GET_QUESTIONS {
    b2bOnboardingAnswer {
      id
      organizationId
      currentQuestionId
      requestSSOActivation
      answers {
        id
        questionId
        choiceIds
      }
      questions {
        id
        mainTitle
        title
        description
        helpText
        maxSelect
        choiceType
        questionView
        showCheckBox
        choices {
          id
          text
          image
          imageType
        }
      }
    }
    myProfile {
      id
      email
    }
    organisation {
      id
      name
      accountType
    }
    roles {
      organisationAdmin
    }
    AccessPermissions_getUserPermissions {
      cloud {
        packageId
      }
    }
  }
`;

const SAVE_ANSWERS = gql`
  mutation SAVE_ANSWERS($answer: B2BOnboardingAnswerInput!, $nextQuestionId: String, $email: String) {
    b2bOnboardingAnswer(answer: $answer, nextQuestionId: $nextQuestionId, email: $email) {
      id
      organizationId
      currentQuestionId
      requestSSOActivation
      answers {
        id
        questionId
        choiceIds
      }
    }
  }
`;

export const StepperContext = React.createContext({});

export const StepperProvider = ({ children }) => {
  const router = useRouter();
  const redirectNonAdmins = (isOrgAdmin) => {
    if (!isOrgAdmin && !CYPRESS) {
      router.push(`${LEARN_SITE}/dashboard`);
    } else if (!isOrgAdmin && CYPRESS) {
      // Setting cypress redirect
      // creative problems require creative solutions lol
      setCypressRedirect(`${LEARN_SITE}/dashboard`);
    }
  };
  const [getQuestions, { data, loading: loadingQuestions }] = useLazyQuery(GET_QUESTIONS, {
    errorPolicy: 'all',
    onCompleted() {
      redirectNonAdmins(get(data, 'roles.organisationAdmin'));
    },
    onError() {
      const isOrgAdmin = get(data, 'roles.organisationAdmin');
      if (!isOrgAdmin) {
        redirectNonAdmins(get(data, 'roles.organisationAdmin'));
        ErrorMessage({
          message: 'You are not and Admin',
          description: 'Sorry this is for admins only. Taking you to the student view',
        });
      } else {
        ErrorMessage({
          message: 'We had a problem getting the questions',
          description: 'Please refresh the page to try again.',
        });
      }
    },
  });
  const [saveAnswers, { loading: savingAnswersLoading }] = useMutation(SAVE_ANSWERS, {
    onError() {
      ErrorMessage({
        message: 'We had a problem saving that answer',
        description: 'Please click the continue button to try again.',
      });
    },
  });

  const [selectedChoices, setSelectedChoices] = useState([]);
  const [cypressRedirect, setCypressRedirect] = useState(false);
  const [canGoBack, setCanGoBack] = useState(false);
  const questions = get(data, 'b2bOnboardingAnswer.questions', []);
  const email = get(data, 'myProfile.email');
  const answer = get(data, 'b2bOnboardingAnswer', {});
  const headerTitles = [
    ...(questions || []).map((question) => ({
      id: question.id,
      title: question.mainTitle,
    })),
  ];
  const questionIndex = questions.findIndex((question) => question.id === answer?.currentQuestionId);
  const currentQuestion = get(questions, `[${questionIndex}]`, {});
  const nextQuestionId = get(questions, `[${questionIndex + 1}].id`) || currentQuestion.id;
  const previousQuestionId = get(questions, `[${questionIndex - 1}].id`) || currentQuestion.id;
  const currentAnswer = (answer?.answers || []).find((a) => a.questionId === answer?.currentQuestionId);
  const organizationName = get(data, 'organisation.name');
  const packageId = get(data, 'AccessPermissions_getUserPermissions.cloud.packageId') || '';
  const canUseSSO = /plus/gi.test(packageId);
  const accountType = get(data, 'organisation.accountType');
  const freeTrial = accountType === 'TRIAL' ? true : false;

  // When the question index is updated
  useEffect(() => {
    setCanGoBack(questionIndex > 0);
  }, [questionIndex]);

  // Set page view segment event
  useEffect(() => {
    if (currentQuestion.mainTitle && /questions/gi.test(router.pathname)) {
      trace.view(currentQuestion.mainTitle.toLowerCase());
    }
  }, [currentQuestion, router.pathname]);

  // Wait for browser to load so we have a auth0 id :)
  useEffect(() => {
    if (process.browser) {
      getQuestions();
    }
  }, [process.browser]);

  const setAnswer = (answerAnswers, requestSSOActivation) => ({
    ...answer,
    ...(!isUndefined(requestSSOActivation) ? { requestSSOActivation } : {}),
    answers: answer.answers.map((a) => {
      if (a.id === answerAnswers.id) return answerAnswers;
      return a;
    }),
  });

  // Move questions according to the sent nextQuestionId
  const setQuestion = (answerAnswers, nextQuestionId, requestSSOActivation) => {
    const savedAnswer = setAnswer(answerAnswers, requestSSOActivation);
    const withoutTypeName = omitDeep(savedAnswer, ['__typename', 'questions']);

    return saveAnswers({
      variables: {
        answer: withoutTypeName,
        nextQuestionId: nextQuestionId,
        ...(requestSSOActivation && !freeTrial ? { email } : {}),
      },
      optimisticResponse: {
        __typename: 'Mutation',
        b2bOnboardingAnswer: {
          ...savedAnswer,
          currentQuestionId: nextQuestionId,
          nextQuestionId: nextQuestionId,
        },
      },
    });
  };

  const nextQuestion = (answerAnswers, requestSSOActivation) => {
    trace.click('Continue', { pageName: currentQuestion.mainTitle.toLowerCase() });
    // Save and increment question index
    return setQuestion(answerAnswers, nextQuestionId, requestSSOActivation);
  };

  const previousQuestion = (answerAnswers, requestSSOActivation) => {
    trace.click('Previous', { pageName: currentQuestion.mainTitle.toLowerCase() });
    // Save and decrement question index
    setQuestion(answerAnswers, previousQuestionId, requestSSOActivation);
  };

  const setCurrentQuestionByIndex = (answerAnswers, questionIndex) => {
    trace.click('Jump to Step', { pageName: currentQuestion.mainTitle.toLowerCase() });
    const savedAnswer = setAnswer(answerAnswers);
    const nextQuestionId = savedAnswer.questions[questionIndex].id;
    setQuestion(answerAnswers, nextQuestionId);
  };

  return (
    <StepperContext.Provider
      value={{
        loadingQuestions,
        savingAnswersLoading,
        questions,

        // Used to detect a redirect in cypress :)
        cypressRedirect,
        canUseSSO,
        freeTrial,
        organizationName,
        setCypressRedirect,
        currentQuestion,
        questionIndex,
        headerTitles,
        canGoBack,
        answer,
        currentAnswer,
        nextQuestion,
        previousQuestion,
        setCurrentQuestionByIndex,
        selectedChoices,
        setSelectedChoices,
      }}
    >
      {children}
    </StepperContext.Provider>
  );
};
