import pino from 'pino';
import { getConfig } from '../config/get-config';

const { LOG_LEVEL } = getConfig();

const color = (level) => {
  if (level === 'error') return 'color: #f5222d;';
  if (level === 'warn') return 'color: #faad14;';
  return 'color: #0d0a74;';
};
const write = ({ level, message, error, params }) => {
  let data = [];
  if (error) data.push(error);
  if (Array.isArray(params) && params.length) data = data.concat(...params);
  console.log(`%c${level}\t`, color(level), message, ...data);
};

/** Log lib init and config http://getpino.io */
const log = pino({
  formatters: {
    level: (label) => {
      return { level: label };
    },
  },
  serializers: { error: pino.stdSerializers.err },
  level: LOG_LEVEL,
  browser: { write },
});

/**
 * Declaritive interface for log event.
 * @param {Object} details           - event attributes
 * @param {string} details.level     - trace|debug|info|page|warn|error
 * @param {string} details.message   - event description
 * @param {string} [details.scope]   - service or other context name
 * @param {array} [details.error]    - error object
 * @param {array} [details.params]   - event parameters
 */
export const logEvent = (details) => {
  if (typeof details.message === 'undefined') {
    return log.error(`invalid log event, no message attribute: ${JSON.stringify(details)}`);
  }
  if (!Object.keys(log.levels.values).includes(details.level)) {
    return log.error(`logger called with invalid level: ${details.level}`);
  }
  return log[details.level](details);
};

/** Simple interface for logging events. */
export const logger = {
  trace: (message, ...params) => logEvent({ level: 'trace', message, params }),
  debug: (message, ...params) => logEvent({ level: 'debug', message, params }),
  info: (message, ...params) => logEvent({ level: 'info', message, params }),
  warn: (message, ...params) => logEvent({ level: 'warn', message, params }),
  error: (message, error, ...params) => logEvent({ level: 'error', message, error, params }),
};

/** Check given level is defined in the log handler. */
export const levelIsValid = (level) => {
  return Object.values(log.levels.labels).includes(level);
};
