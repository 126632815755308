import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { get } from 'lodash';
import { getConfig } from '../config/get-config';
import gql from 'graphql-tag';
import { trace } from './trace';
import { authService } from '../services/auth-service';

const { GRAPHQL_API_ENDPOINT, STAGE, BUILD_COMMIT, BUILD_NUMBER, CYPRESS } = getConfig();

const getCorrelationIds = (context) => {
  try {
    const headers = get(context, `response.headers`);
    return {
      'x-correlation-id': headers.get('x-correlation-id'),
      'x-correlation-user-id': headers.get('x-correlation-user-id'),
      'x-correlation-org-id': headers.get('x-correlation-org-id'),
      'x-correlation-package-id': headers.get('x-correlation-package-id'),
    };
  } catch (e) {
    return '';
  }
};

const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    const correlationIds = getCorrelationIds(operation.getContext());
    const correlationId = get(correlationIds, 'x-correlation-id');
    graphQLErrors.forEach(({ message, path, locations }) => {
      const error = new Error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, CorrelationId: ${correlationId}`
      );
      trace.error(message, error, graphQLErrors);
      const errorMessage = `[GraphQL error]: Message: ${message}, Path: ${path}, CorrelationId: ${correlationId}`;
      console.log(errorMessage);
    });
  }

  if (networkError) {
    const error = new Error(`[Network error]: ${networkError}`);
    trace.error('Network error', error);
    const errorMessage = `[Network error]: ${networkError}`;
    console.log(errorMessage);
  }
});

const customFetch = (uri, options) => {
  const { query } = JSON.parse(options.body);
  const obj = gql`
    ${query}
  `;
  return fetch(
    `${GRAPHQL_API_ENDPOINT}?operationName=${encodeURI(
      get(obj, 'definitions[0].selectionSet.selections[0].name.value')
    )}`,
    options
  );
};

const httpLink = () => {
  let options = {
    uri: GRAPHQL_API_ENDPOINT,
  };
  if (CYPRESS) {
    options = { fetch: customFetch };
  }
  return createHttpLink(options);
};

const authLink = setContext(async (_, { headers }) => {
  let token;
  try {
    token = await authService.getToken();
  } catch (e) {} // eslint-disable-line no-empty

  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token ? token : ''}`,
      'apollographql-client-name': `checkout-frontend-${STAGE}`,
      'apollographql-client-version': `${BUILD_NUMBER}_${BUILD_COMMIT}`,
    },
  };
});

const getClient = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, httpLink()]),
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

export { getClient };
