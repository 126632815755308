const testConfig = {
  ACG_B2B_ONBOARDING_DOMAIN: 'https://staging-onboarding.acloud.guru',
  GRAPHQL_API_ENDPOINT: 'https://test-api.acloud.guru/bff/graphql',
  LOG_LEVEL: 'debug',
  LOG_STAGE: 'staging',
  AUTH0_DOMAIN: 'staging-acloudguru.auth0.com',
  AUTH0_CUSTOM_DOMAIN: 'staging-auth.acloud.guru',
  AUTH0_API_AUDIENCE: 'https://staging-acloudguru.auth0.com/api/v2/',
  AUTH0_CLIENT_ID: 'fljNY6H0JVMd41gR0o7ATdSiETUD78rh',
  IGNORE_SEGMENT_SEND: false,
  SEGMENT_API_KEY: '16LwELg1PMvCSCo5r3kC6qnSTpFBcoli',
  PS_LOGOUT_URL: 'http://app-stage.pluralsight.com/id/signout',
  ALLOWED_DOMAINS: [
    'http://127.0.0.1',
    'http://localhost',
    'https://*.acloud.guru',
    'https://brave-saha-7b070c.netlify.com',
    'https://*.auth0.com',
    'https://acg',
    'https://pocket-school.netlify.app',
    'https://*.linuxacademy.com',
    'https://*.pluralsight.com',
  ],
};

export { testConfig };
