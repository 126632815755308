const productionConfig = {
  ACG_B2B_ONBOARDING_DOMAIN: 'https://onboarding.acloud.guru',
  GRAPHQL_API_ENDPOINT: 'https://prod-api.acloud.guru/bff/graphql',
  LOG_LEVEL: 'warn',
  LOG_STAGE: 'production',
  AUTH0_DOMAIN: 'acloudguru.auth0.com',
  AUTH0_CUSTOM_DOMAIN: 'auth.acloud.guru',
  AUTH0_API_AUDIENCE: 'https://acloudguru.auth0.com/api/v2/',
  AUTH0_CLIENT_ID: 'JGSrENLfEp0LIAHTHja671Sde0pu0Q0Z',
  IGNORE_SEGMENT_SEND: false,
  SEGMENT_API_KEY: '17QLfIqmWHPL9nbxHsr0kRxM5x28qbJ2',
  LEARN_SITE: 'https://learn.acloud.guru',
  PS_LOGOUT_URL: 'http://app.pluralsight.com/id/signout',
  ALLOWED_DOMAINS: [
    'https://acloud.guru',
    'https://*.acloud.guru',
    'https://acloudguru.firebaseapp.com',
    'https://mr-magoriums-wonder-emporium.netlify.app',
    'https://*.linuxacademy.com',
    'https://acg',
    'https://*.auth0.com',
    'https://*.pluralsight.com',
  ],
};

export { productionConfig };
