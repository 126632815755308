import { ConfigProvider, learnTheme } from '@a-cloud-guru/rainbow-ui';
import { AuthProvider } from '@a-cloud-guru/react-auth';
import { ApolloProvider } from '@apollo/client';
import { authService } from '../services/auth-service';
import { bugsnag } from '../services/bugsnag';
import { Identify } from '../components/util/Identify';
import { getClient } from '../util/makeApolloClient';
import { CypressRedirect } from '../util/CypressRedirect';
import { StepperProvider } from '../context/stepper.context';

import '../index.less';

const { ErrorBoundary: BugsnagErrorBoundary } = bugsnag;

function MyApp({ Component, pageProps }) {
  return (
    <BugsnagErrorBoundary>
      <AuthProvider client={authService}>
        <ApolloProvider client={getClient}>
          <ConfigProvider theme={learnTheme}>
            <StepperProvider>
              <CypressRedirect />
              <Identify />
              <Component {...pageProps} />
            </StepperProvider>
          </ConfigProvider>
        </ApolloProvider>
      </AuthProvider>
    </BugsnagErrorBoundary>
  );
}

export default MyApp;
