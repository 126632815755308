import * as snippet from '@segment/snippet';
import qs from 'query-string';
import { getConfig } from '../config/get-config';

const { SEGMENT_API_KEY, IGNORE_SEGMENT_SEND } = getConfig();

if (!SEGMENT_API_KEY) throw new Error('No apiKey argument for Segment service');
if (IGNORE_SEGMENT_SEND) console.warn('Segment sends are disabled');

export const sendEnabled = () => {
  if (IGNORE_SEGMENT_SEND) return false;
  if (!global.window || !global.window.analytics) {
    console.warn('Segment tracking not available');
    return false;
  }
  return true;
};

export const sendCurrentPage = (name, properties) => {
  if (!sendEnabled()) return;
  const query = qs.parse(window.location.search);
  const route = global.window.location.pathname;
  return sendPage(name || route, properties || query);
};

export const sendPage = (name, query) => {
  if (!sendEnabled()) return;
  return global.window.analytics.page(name, query);
};

export const sendTrack = (...params) => {
  if (!sendEnabled()) return;
  return global.window.analytics.track(...params);
};

export const sendIdentify = (...params) => {
  if (!sendEnabled()) return;
  return global.window.analytics.identify(...params);
};

export const Snippet = () => (
  <script
    dangerouslySetInnerHTML={{
      __html: snippet.min({
        apiKey: SEGMENT_API_KEY,
        page: false,
      }),
    }}
  />
);
