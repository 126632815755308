import React, { useContext } from 'react';
import { getConfig } from '../config/get-config';
import { StepperContext } from '../context/stepper.context';

const { CYPRESS } = getConfig();

/**
 * If we set the cypressRedirect content somewhere in our app this component will
 * render an empty span with something cypress selectable
 *
 * @returns Empty react component or span with data-cy set to redirect url
 */
export const CypressRedirect = () => {
  const { cypressRedirect } = useContext(StepperContext);

  if (!CYPRESS) return <></>;
  return <span data-cy={cypressRedirect} />;
};
