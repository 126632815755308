import React, { useEffect } from 'react';
import { useAuth } from '@a-cloud-guru/react-auth';
import get from 'lodash.get';
import { trace } from './../../util/trace';

export const Identify = () => {
  const { viewer } = useAuth();
  const id = get(viewer, 'id');

  useEffect(() => {
    if (id) {
      trace.identify(viewer);
    }
  }, [id]);

  return <></>;
};
