const { NEXT_PUBLIC_APP_VERSION } = process.env;

const commonConfig = {
  ACG_ENV: process.env.NEXT_PUBLIC_REACT_APP_ACG_ENV || process.env.NEXT_PUBLIC_ACG_ENV,
  NEXT_PUBLIC_APP_VERSION: NEXT_PUBLIC_APP_VERSION || 'local',
  BUILD_NUMBER: process.env.NEXT_PUBLIC_REACT_APP_BUILD_NUMBER || '0',
  BUILD_COMMIT: process.env.NEXT_PUBLIC_REACT_APP_BUILD_COMMIT || '0',
  STAGE: process.env.NEXT_PUBLIC_REACT_APP_ACG_ENV,
  CYPRESS: false,
  LOG_LEVEL: 'debug',
  LOG_STAGE: 'local',
  BUGSNAG_API_KEY: '204bd0f8b4d977ff107db40013ef527c',
  SEGMENT_API_KEY: 'WZosdh2DgTUU3F2PztX8qJawCibGr6Es',
  IGNORE_SEGMENT_SEND: false,
  LEARN_SITE: 'https://staging-learn.acloud.guru',
};

export { commonConfig };
