import { colours } from '@a-cloud-guru/rainbow-ui';
import { notification } from 'antd';

export const ErrorMessage = ({ message, description }) =>
  notification.open({
    message,
    description,
    style: {
      background: `${colours.red50}`,
      borderLeftWidth: '4px',
      borderLeftStyle: 'solid',
      borderLeftColor: `${colours.red}`,
    },
  });
