import debounce from 'lodash.debounce';

import * as segmentService from '../services/segment';
import bugsnag from '../services/bugsnag';

import { logger, levelIsValid } from './logger';

/**
 * Route event info to analytics, bug service and console logs (by level/verb).
 * `verb` determines routing and standardised suffix for Segment and Amplitude.
 * `track` is the generic fallback verb, but being explicit is recommended.
 * @param {Object} params
 * @param {String} params.level        trace|debug|info|warn|error
 * @param {String} [params.content]    Type, message or ID of event
 * @param {String} [params.verb]       track|view|identify|click
 * @param {Object} [params.data]       Key/value event meta
 */
export const traceEvent = ({ content = '', level, verb, data = {}, error }) => {
  if (!levelIsValid(level)) return logger.warn(`Invalid trace level (${level})`);

  // track events/actions in analytics service, routed by verb
  if (verb) {
    const site = 'B2B Onboarding';
    switch (verb) {
      case 'view':
        segmentService.sendCurrentPage(`${site} | ${content} [view]`, { site, ...data });
        break;
      case 'identify':
        segmentService.sendIdentify(data.userId || data.id, data);
        bugsnag.identifyUser(data);
        break;
      case 'click':
      default:
        segmentService.sendTrack(`${site} | ${content} [${verb}]`, { site, ...data });
        bugsnag.leaveBreadcrumb(content, data);
        break;
    }
  }

  // logging errors uses specific param order
  if (error) {
    bugsnag.notifyError(error || content, data);
    logger.error(content, error, data);
    return;
  }

  // log everything, let logger's level config decide output
  logger[level](`${content}${verb ? ` [${verb}]` : ''}`, data);
};

// shorthand for pre-defined levels and tracking verbs
const debug = (content, data) => traceEvent({ level: 'debug', content, data });
const info = (content, data) => traceEvent({ level: 'info', content, data });
const track = (content, data) => traceEvent({ level: 'info', content, data, verb: 'track' });
const identify = (data) => traceEvent({ level: 'info', data, verb: 'identify' });
const view = (content, data) => traceEvent({ level: 'info', content, data, verb: 'view' });
const click = (content, data) => traceEvent({ level: 'info', content, data, verb: 'click' });
const warn = (content, data) => traceEvent({ level: 'warn', content, data });
const error = (content, error, data) => traceEvent({ level: 'error', content, data, error });
const debounced = {
  debug: debounce((...args) => debug(...args), 1000),
  info: debounce((...args) => info(...args), 1000),
  track: debounce((...args) => track(...args), 1000),
  click: debounce((...args) => click(...args), 1000),
};
export const trace = { debug, info, identify, track, view, click, warn, error, debounced };

export default trace;
